/* Bar itself */
.navbar {
  background-color: #000a1c;
  display: flex;
  padding: 20px 0;
  z-index: 1;
  width:100%;
  align-content: center;
  justify-items: center;
  justify-content: center;
}

/* Contains the items and spotify feature */
.nav-items-container {
  display: flex;
  align-items: center;
  width: 95%;
}

/* Contains the links */
.nav-items {
  display: flex;
  justify-content: flex-start;
  flex: 1;
}

/* Links */
.nav-item {
  color: white;
  text-decoration: underline;
  padding: 5px;
}

/* NowPlaying feature */
.nowplaying {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
