.now-playing {
    display: flex;
    justify-content: center;
}

.now-playing .spotify-logo {
    width: 40px; /* adjust as necessary */
    height: 40px; /* adjust as necessary */
    margin-left: 10px;
    margin-top: 2px;
    filter: brightness(0.4); /* change the color to black */
    justify-self: center;
}

.now-playing:hover .spotify-logo {
    filter: brightness(1); /* change the color to white when hovered */
}

.now-playing p {
    color: #808080; /* grey color */
    margin: 0;
    white-space: nowrap; /* keeps text on a single line */
    text-align: right;
}

.now-playing:hover p {
    color: #fff; /* change the color to white when hovered */
}

.now-playing .songname {
    color: #808080; /* grey color */
    width: 30;
    white-space: nowrap; /* keeps text on a single line */
    text-align: right;
}

.now-playing:hover .songname {
    color:#fff;
}